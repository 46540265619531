@import '../../styles/colors';

$left-menu-width: 200px;
$header-height: 48px;
$footer-height: 40px;

.Layout {
  &-LeftPanel {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: #3c4b64;
    width: $left-menu-width;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;

    &-Logo {
      width: 80%;
      margin-top: 20px;
      margin-bottom: 30px;
    }

    &-Menu {
      background-color: transparent;
      border-right: none;

      .ant-menu-item a,
      .ant-menu-submenu-title {
        color: rgb(233, 233, 233);
      }

      .ant-menu-item-selected {
        background-color: rgb(70, 84, 108) !important;
      }

      .ant-menu {
        background-color: transparent;
      }

      .ant-layout-sider-trigger {
        position: initial;
        background-color: transparent;
      }

      &Item {
        display: flex;
        align-items: center;
      }
    }
  }

  &-Header {
    position: fixed;
    left: $left-menu-width;
    right: 0;
    top: 0;
    height: $header-height;
    display: flex;
    align-items: center;
    padding: 0 5px 0 15px;
    background-color: white;
    z-index: 100;
    border-bottom: 1px solid #d8dbe0;

    &-UserMenu {
      padding: 0px 10px;
      &:hover {
        cursor: pointer;
      }
    }

    &-Title {
      font-size: 22px;
      font-weight: bold;
    }
  }

  &-Content {
    margin-top: $header-height;
    margin-bottom: $footer-height;
    margin-left: $left-menu-width;
    padding: 10px;
  }

  &-Footer {
    position: fixed;
    left: $left-menu-width;
    right: 0;
    bottom: 0;
    height: $footer-height;
    display: flex;
    align-items: center;
    padding: 0 5px;
    background-color: white;
    border-top: 1px solid #d8dbe0;
  }
}
