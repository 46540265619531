.payment_button {
  visibility: hidden;
}

.add_payment_button {
  position: absolute;
  right: 0;
  top: 0;
  visibility: hidden;
}

.user_payments {
  position: relative;
  display: grid;
  grid-template-columns: max-content max-content max-content minmax(10px, max-content) max-content max-content max-content max-content min-content;
  grid-gap: 5px;
  align-items: center;

  &:hover {
    .payment_button {
      visibility: visible;
    }
  }
}

.payment_comment {
  grid-column: 1 / -1;
}

.payment_comment_label {
  font-size: 90%;
  color: gray;
}

.active {
  visibility: visible;
}

.title {
  font-weight: bold;
  color: rgb(109, 109, 109);
  padding-right: 0px;
  min-width: 70px;
}

.payment_sum {
  font-weight: bold;
  font-size: 120%;
  color: rgb(49, 49, 49);
  min-width: 60px;
  min-height: 30px;
  border: 1px solid rgb(233, 233, 233);
  border-radius: 2px;
  padding: 2px 7px;
  background-color: white;
}

.payment_status {
  margin-left: 15px;
}

.payment_sum_payed {
  text-align: end;
  margin-right: 0;
}

.calc_info {
  margin-top: 20px;
  padding: 15px 15px 5px;
  // border-top: 1px solid rgb(226, 226, 226);
}

.calc_info_main {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.calc_info_sum {
  width: 80px;
  margin-left: 15px;
  margin-right: 15px;
}

.calc_prev_payment {
  margin-bottom: 10px;
}

.detail_item {
  color: green;
  font-weight: bold;
  font-size: 90%;
}

.detail_warning {
  color: red;
}

.calc_trainings {
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
}

.calc_trainings_item {
  margin-right: 45px;
}

.calc_trainings_list {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  grid-column-gap: 10px;
  font-size: 92%;
  margin-top: 7px;
}
