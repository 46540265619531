.UserNotesIcon {
  color: rgb(46, 140, 247);
  cursor: pointer;
}

.UserNotes {
  min-height: 400px;

  .UserNote {
    &-Info {
      color: gray;
      font-weight: bold;
      font-size: 80%;
    }

    &-Text {
      border: 1px solid gray;
      border-radius: 3px;
      font-size: 90%;
      padding: 5px;
      margin-bottom: 10px;
      background-color: white;
    }

    &__Important {
      background-color: sandybrown;
    }

    &__Important-Warn {
      background-color: #ff4949;
    }
  }
}

.LogItem-Header {
  font-size: 90%;
  font-weight: bold;
}
