.SourcesTable {
  margin-top: 20px;

  table {
    width: auto;
    border: 1px solid rgb(218, 218, 218);
  }

  .SourceGroup {
    color: rgba(27, 68, 201, 0.705);
    font-weight: bold;
    font-size: 18px;
    min-width: 350px;
  }

  .Source {
    margin-left: 30px;
    font-weight: bold;
  }

  .ActionsMenu {
    color: gray;
    font-weight: normal;
  }
}
