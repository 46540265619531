.payment_main {
  display: flex;
  justify-content: left;
  align-items: center;
  font-weight: bold;
  font-size: 90%;
  cursor: pointer;
  padding-left: 10px;
}

.payment_payed {
  color: green;
  display: flex;
  align-items: center;
}

.payment_rub {
  font-size: 80%;
  display: inline-block;
  margin-left: 4px;
}

.payment_to_pay {
  color: gray;
  display: flex;
  align-items: center;
}

.no_payments {
  color: gray;
  text-align: center;
}

.payments_divider {
  width: 15px;
  text-align: center;
}

.payment_comment {
  display: inline-block;
  color: rgb(100, 161, 240);
  margin-left: 7px;
}

.payment_terminal {
  height: 16px;
  margin-left: 5px;
}
