.UsersPage {
  &-Filter {
    display: flex;
    margin-bottom: 10px;
  }

  &-Header {
    display: flex;
    align-items: center;
    height: 24px;

    .Header-Title {
      font-size: 22px;
      font-weight: bold;
    }
  }

  &-SourcesList {
    width: 450px;
    margin-bottom: 10px;
    overflow-y: auto;

    &-Loading {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
    }
  }

  &-Selected {
    width: 450px;
    display: flex;
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid rgb(224, 224, 224);
    flex-wrap: wrap;
  }

  &-SelectedSource {
    background-color: rgb(1, 150, 236);
    color: white;
    display: inline-block;
    padding: 2px 4px;
    border-radius: 3px;
    margin-right: 7px;
    margin-bottom: 4px;
  }

  .Users-Table {
    th {
      position: sticky;
      top: 48px;
      z-index: 1;
    }

    .UserInfo {
      &-Label {
        font-weight: bold;
        color: #6b7074;
        min-width: 120px;
        display: inline-block;
        font-size: 80%;
      }

      &-Main {
        display: flex;
        justify-content: space-between;
      }

      &-Name {
        font-weight: bold;
        font-size: 18px;
      }

      &-Group {
        color: black;
      }

      &-Status {
        color: rgb(90, 90, 90);
        font-size: 90%;
      }

      &-Phone {
        color: #337ab7;
        font-weight: bold;
      }

      &-Desc {
        color: #808080;
        font-size: 90%;
      }

      &-Abonnement,
      &-Discount {
        font-size: 80%;
        color: #82abd3;
        font-weight: bold;
      }
    }
  }
}
