.toolbar {
  margin-bottom: 10px;
  display: flex;
}

.filter {
  align-items: flex-end;
}

.calc_mode {
  display: flex;
  align-items: flex-end;
  padding-bottom: 5px;
}

.calc_mode_label {
  margin-left: 4px;
  cursor: pointer;
}

.filter_check {
  padding-bottom: 5px;
}

.calc_toolbar {
  display: flex;
}
