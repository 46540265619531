.UserTrainingStatusIcon {
  height: 1.2em;

  svg {
    width: 1.2em;
    height: 1.2em;
  }

  &-Visit {
    color: #5cb85c;
  }

  &-Skip {
    color: #d9534f;
  }

  &-HealthSkip {
    color: #999999;
  }

  &-HealthCertificate {
    color: #b200ff;
  }

  &-Vacation {
    color: #5bc0de;
  }

  &-Test {
    color: #5cb85c;
  }

  &-TestSkip {
    color: #d9534f;
  }
}

.fs-v-text-color-training-test {
  color: #ff8228 !important;
}
