.user_visits {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 25px;
}

.user_visit_info {
  display: flex;
  align-items: center;
  width: 50%;
  margin: 0;
  margin-bottom: 3px;
  height: 50px;
  padding: 7px;

  &:hover {
    background-color: rgb(238, 238, 238);
    border-radius: 5px;
  }
}

.user_visit_icon {
  padding: 8px;
  padding-right: 15px;
  font-size: 130%;
  cursor: pointer;
}

.user_visit_drop_icon {
  padding-right: 15px;
}

.user_name {
  font-weight: bold;
  font-size: 110%;
}

.user_visit_status_title {
  color: gray;
}

.user_visit_menu_item {
  display: flex;
  align-items: center;
}
