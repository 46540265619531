@import '../../styles/colors';

.FormItem {
  margin-right: 10px;
  margin-bottom: 7px;

  &-Label {
    color: rgb(87, 87, 87);
  }

  &-Control {
    display: flex;
    align-items: center;
    min-height: 30px;
  }

  &-ControlSimple {
    min-height: 30px;
  }
}
