.error_operation {
  background-color: rgb(255, 172, 172);
}

.success_operation {
  background-color: #66bb6a;
}

.operation_detail {
  padding-left: 20px;
  max-width: 850px;
  word-break: break-all;
}

.payment_operations_table {
  min-height: 700px;

  :global .ant-table-tbody > tr.ant-table-row:hover > td {
    background: unset;
  }
}
