.Login {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('../../assets/images/background.jpg');
  }

  &-Logo {
    width: 25%;
    z-index: 1;
  }

  &-Form {
    padding: 60px 40px 15px 40px;
    background-color: rgb(172, 172, 172);
    border-radius: 8px;
    min-width: 400px;
    margin-left: 250px;
  }
}

.user_logged {
  position: static;
}
