body {
  //background-color: rgb(235, 237, 239) !important;
  background-color: white;
  height: initial;
}

.ant-notification {
  max-width: 700px;
  width: auto;
  z-index: 2000;
}

.ant-back-top {
  right: 15px;
}

.Notification-Error {
  background-color: rgb(255, 202, 202);
}

.ant-modal {
  .ant-modal-body {
    position: relative;

    .ant-spin {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.SpinContainer {
  position: relative;

  .ant-spin {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.display-flex {
  display: flex;
  align-items: center;
}
