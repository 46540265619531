.UserDocument {
  &Icon {
    cursor: pointer;

    &_text-primary {
      color: rgb(46, 140, 247);
    }

    &_text-danger {
      color: rgb(255, 77, 77);
    }

    &_text-warning {
      color: #f2c037;
    }

    &_text-purple {
      color: #b109ff;
    }
  }

  &-FormItem {
    margin-bottom: 10px;

    & > label {
      display: block;
      font-weight: bold;
    }
  }

  &-DeleteFileButton {
    display: inline-block;
    margin-left: 10px;
    color: red;
    cursor: pointer;
  }

  &-FileInput {
    &__hidden {
      visibility: hidden;
    }
  }
}
