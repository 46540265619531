.page_item {
  background-color: rgb(230, 230, 230);
}

.page_item_content {
  display: grid;
  grid-template-columns: 80px 1fr;
  grid-column-gap: 40px;
  align-items: center;
}

.page_item_title {
  font-size: 28px;
  font-weight: 700;
}

.page_item_image {
  // height: 250px;
  width: 100%;
}

.page {
  display: grid;
  grid-template-columns: repeat(2, 450px);
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  padding-top: 30px;
}
