.groups_list {
  max-height: 80vh;
  overflow: auto;
  padding: 6px;
}

.groups_block {
  display: flex;
  flex-wrap: wrap;
  max-width: 460px;
  margin-bottom: 10px;
}

.group {
  color: black;
  padding: 4px 9px;
  cursor: pointer;

  &:hover {
    background-color: rgb(0, 140, 255);
    color: white;
  }
}

.group_selected {
  background-color: rgb(132, 199, 253);
}

.divider {
  font-weight: bold;
  background-color: rgb(241, 241, 241);
  padding: 3px 20px;
  margin-bottom: 5px;
  height: calc(6px + 1em);
  line-height: 1em;
}
