.Loading {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('../../assets/images/background.jpg');
    z-index: 0;
  }

  &-Logo {
    height: 30%;
    z-index: 1;
  }

  &-Ball {
    height: 80px;
    z-index: 1;
  }
}
