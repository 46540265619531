.UserEditDialog {
  min-width: 850px !important;

  .ant-modal-body {
    background-color: rgb(252, 252, 252);
  }

  &-UserId {
    color: gray;
    font-size: 80%;
    margin-left: 20px;
  }

  &-InfoPanel,
  &-NotesPanel,
  &-PaymentsPanel,
  &-TrainingsPanel {
    width: 100%;
    height: 100%;
    min-height: 500px;
  }

  &-NotesPanel__hidden,
  &-PaymentsPanel__hidden,
  &-TrainingsPanel__hidden {
    display: none;
  }

  &__existing {
    .UserEditDialog-Form {
      display: flex;
    }

    min-width: 850px !important;

    .ant-modal-body {
      min-height: 550px;
    }
  }

  &-Menu {
    width: 170px;
    height: 500px;
    background-color: rgb(252, 252, 252);
    margin-right: 20px;
  }

  &-ClientActions {
    display: flex;
    justify-content: flex-start;
    margin-top: 25px;
    margin-bottom: 10px;

    button {
      margin-right: 10px;
    }
  }

  &-ExistingUserInfo {
    padding-bottom: 10px;

    &-User {
      font-size: 85%;
      color: rgb(201, 130, 0);
      font-weight: bold;
    }

    &-Actions {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      button {
        padding-left: 0;
      }
    }
  }

  &-TestTraining {
    width: 1000px !important;

    .ant-picker-panel {
      border: none;
    }
  }

  &-AbonnementSelect,
  &-DiscountSelect {
    width: 85%;
    max-width: 85%;
  }
}

.CalendarCell {
  box-sizing: border-box;
  border-top: 1px solid rgb(216, 216, 216);

  &-Day {
    font-weight: bold;
  }

  &-Time {
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
  }

  &__has-training {
    color: rgba(6, 103, 230, 0.767);
  }

  &__selected {
    background-color: rgba(201, 224, 253, 0.767);
    border: 1px solid rgba(6, 103, 230, 0.767);
    border-radius: 6px;
  }
}

.SelectUserDialog{
  &-UsersPanel {
    min-height: 30px;
  }
  &-User {
    padding-top: 5px;
    padding-bottom: 5px;
    &:hover {
      background-color: rgb(0, 140, 255);
      color: white;
      cursor: pointer;
    }
  }
  &-UserInfo {
    display: grid;
    grid-template-columns: 1fr max-content;
    font-weight: bold;
  }
}

.UserEditFamilyInfoDialog{
  &-Actions {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-column-gap: 10px;
  }
}